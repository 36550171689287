import React from 'react'
import clsx from 'clsx'
import {
  CheckIcon,
  ChevronUpDownIcon,
  XCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid'
import {
  Combobox,
  ComboboxInput,
  Label,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react'
import { useDebouncedCallback } from 'use-debounce'

import { AutocompleteProps } from './autocomplete'
import { defaultIdAccessor } from './acFunctions'

export const AutocompleteInput: React.FC<AutocompleteProps<any>> = ({
  label,
  placeholder,
  data,
  renderer,
  itemCount,
  selectedItem,
  error,
  required,
  showExistingButton = true,
  showSearchIcon,
  noBorder,
  className,
  onSearch,
  onClear,
  onSelect,
}) => {
  const [selectedRow, setSelectedRow] = React.useState<any | null>(
    selectedItem ?? null,
  )

  const handleChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value)
    },
    300,
    {
      maxWait: 300,
      leading: true,
      trailing: true,
    },
  )

  const handleSelect = React.useCallback(
    (item: any | null) => {
      setSelectedRow(item)
      onSelect(item)
    },
    [onSelect],
  )

  const handleClear = React.useCallback(() => {
    setSelectedRow(null)
    if (onClear) {
      onClear()
    }
  }, [onClear])

  React.useEffect(() => {
    if (selectedItem !== undefined) {
      setSelectedRow(selectedItem)
    }
  }, [selectedItem])

  const idAccessorFn = renderer.idAccessor || defaultIdAccessor

  return (
    <Combobox
      as="div"
      value={selectedRow}
      nullable
      onChange={handleSelect}
      className={className}
    >
      {label ? (
        <Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400">
          {label}
          {required ? (
            <span className="text-error-500 dark:text-error-300">*</span>
          ) : null}
        </Label>
      ) : null}
      <div className="relative mt-0.5">
        <ComboboxInput
          className={clsx(
            'w-full select-none border-0 rounded-md py-2.25 pl-3 pr-12 text-sm sm:leading-6',
            'bg-white dark:bg-slate-900 text-gray-900 dark:text-slate-400',
            {
              'shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset ring-gray-300 dark:ring-slate-500':
                !noBorder,
              'ring-0': noBorder,
              'focus:ring-primary-600 dark:ring-primary-400':
                !noBorder && !!!error,
              'focus:ring-error-600 dark:focus:ring-error-500':
                !noBorder && !!error,
            },
          )}
          autoComplete="off"
          placeholder={placeholder}
          onChange={handleChange}
          displayValue={renderer.labelAccessor ?? ''}
        />
        {error ? (
          <p className="absolute inset-y-10 text-sm text-error-600 dark:text-error-400 inset-y-0">
            {error}
          </p>
        ) : null}
        <div className="absolute flex item-center right-0 top-2.5">
          {selectedRow ? (
            <ComboboxButton
              onClick={handleClear}
              className="rounded-r-md px-2 focus:outline-none"
            >
              <XCircleIcon
                className="h-5 w-5 text-gray-400 dark:text-slate-300"
                aria-hidden="true"
              />
            </ComboboxButton>
          ) : null}
          {showExistingButton ? (
            <ComboboxButton className="rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400 dark:text-slate-300"
                aria-hidden="true"
              />
            </ComboboxButton>
          ) : null}
          {showSearchIcon && !showExistingButton ? (
            <div className="px-2 focus:outline-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400 dark:text-slate-300"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {data.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-slate-900 py-1 text-base shadow-lg ring-1 ring-black dark:ring-slate-500 ring-opacity-5 focus:outline-none sm:text-sm">
            {data.map((item: any) => {
              if (!item) {
                console.log('Warning: autocomplete item missing')
                return null
              }
              return (
                <ComboboxOption
                  key={`ac-${idAccessorFn(item)}`}
                  value={item}
                  className={({ active }) =>
                    clsx('relative cursor-default select-none py-2 pl-3 pr-9', {
                      'bg-indigo-600 dark:bg-slate-800 text-white dark:text-slate-300':
                        active,
                      'text-gray-900 dark:text-slate-400': !active,
                    })
                  }
                >
                  {({ active, selected }) => {
                    let avatarAsset =
                      'avatarAccessor' in renderer &&
                      renderer.avatarAccessor(item)
                    return (
                      <>
                        {avatarAsset ? (
                          <div className="flex items-center">
                            <img
                              src={avatarAsset.preview}
                              alt={renderer.labelAccessor(item)}
                              className="h-6 w-6 flex-shrink-0 rounded-full"
                            />
                            <span
                              className={clsx('ml-3 truncate', {
                                'font-semibold': selected,
                              })}
                            >
                              {renderer.labelAccessor(item)}
                            </span>
                          </div>
                        ) : (
                          <div className="flex">
                            <span
                              className={clsx('truncate', {
                                'font-semibold': selected,
                              })}
                            >
                              {renderer.labelAccessor(item)}
                            </span>
                            {renderer.subLabelAccessor ? (
                              <span
                                className={clsx('ml-2 truncate', {
                                  'text-primary-200 dark:text-slate-300':
                                    active,
                                  'text-gray-500 dark:text-slate-400': !active,
                                })}
                              >
                                {renderer.subLabelAccessor(item)}
                              </span>
                            ) : null}
                          </div>
                        )}

                        {selected && (
                          <span
                            className={clsx(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              {
                                'text-white dark:text-slate-300': active,
                                'text-primary-600 dark:text-slate-400': !active,
                              },
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )
                  }}
                </ComboboxOption>
              )
            })}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}
