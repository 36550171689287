import React from 'react'
import { useControlField, useField } from 'remix-validated-form'

import { AutocompleteFieldProps } from './autocomplete'
import { AutocompleteInput } from './AutocompleteInput'

export const AutocompleteField: React.FC<AutocompleteFieldProps<any>> = ({
  name,
  label,
  data,
  loading,
  renderer,
  itemCount,
  initialItem,
  required,
  disabled,
  onSearch,
  onSelect,
  onChange: parentChangeFn,
}) => {
  const { error, getInputProps, validate, defaultValue } = useField(name, {
    validationBehavior: {
      initial: 'onBlur',
      whenTouched: 'onBlur',
      whenSubmitted: 'onChange',
    },
  })
  const [value, setValue] = useControlField<any>(name)
  const fieldProps = { ...getInputProps({ onChange: parentChangeFn }) }
  const { onBlur } = fieldProps
  const [selected, setSelected] = React.useState<any | null>(
    initialItem || null,
  )

  const handleSelect = React.useCallback(
    (item: any) => {
      setSelected(item)
      const val = item ? renderer.idAccessor(item) : ''
      setValue(val)
      validate()
      if (onSelect) {
        onSelect(item)
      }
      if (onBlur) {
        // ensure validation triggers
        onBlur()
      }
    },
    [onSelect, onBlur, validate, setValue],
  )

  const handleClear = React.useCallback(() => {
    if (onSelect) {
      onSelect(null)
    }
    setSelected(null)
    setValue('')
    validate()
    if (onBlur) {
      onBlur()
    }
  }, [onSelect, onBlur, setValue])

  React.useEffect(() => {
    // This is a hack to allow us to set an object to the Autocomplete form element
    // The handleSelect function will ensure that on the next pass, the id gets set properly
    // But this way, we can also ensure that the related item gets set as well
    if (
      typeof value !== 'undefined' &&
      typeof value !== 'string' &&
      typeof value !== 'number'
    ) {
      handleSelect(value)
    }
  }, [handleSelect, value])
  const val = value || defaultValue
  return (
    <>
      <input
        type="hidden"
        name={name}
        value={
          (typeof val === 'object' && !!val ? renderer.idAccessor(val) : val) ??
          ''
        }
      />
      <AutocompleteInput
        label={label}
        data={data}
        renderer={renderer}
        itemCount={itemCount}
        selectedItem={selected}
        loading={loading}
        error={error}
        required={required}
        disabled={disabled}
        onSearch={onSearch}
        onSelect={handleSelect}
        onClear={handleClear}
        {...fieldProps}
      />
    </>
  )
}
