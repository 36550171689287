import { Node } from "~common/types"
import { AvatarRenderer } from "./autocomplete"

export const defaultIdAccessor = (item: Node) => item.id

export const defaultAvatarRenderer: AvatarRenderer<any> = {
    avatarAccessor: (item) => item.avatar,
    idAccessor: defaultIdAccessor,
    labelAccessor: (item) => item ? `${item.firstName} ${item.lastName}` : ''
}
